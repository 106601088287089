import React, { useEffect } from 'react';
import { isMobile,isAndroid,isIOS } from 'react-device-detect';

import './App.css';

function App() {

  const PlayStoreLink="https://play.google.com/store/apps/details?id=io.yellotmob.app&utm_campaign=lancamento_app_yellotmob";
  const AppStoreLink="https://apps.apple.com/br/app/yellotmob/id1662112994";
  const BrowserLink="https://yellotmob.com.br/#baixar-app"

  const RedirectToStoreApp=()=>{

    if(isMobile){
      if(isAndroid){
        window.location.href=PlayStoreLink
        return
      }

      if(isIOS){
        window.location.href=AppStoreLink
        return
      }

    }

    window.location.href=BrowserLink;
  }

  useEffect(()=>{
   RedirectToStoreApp()
  },[])

  return (
    <div className="App">
    </div>
  );
}

export default App;
